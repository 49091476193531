<template>
  <div>
    <template v-if="professionals && !hasAcceptedDisclaimer">
      <div class="container post">
        <div class="wrapper">
          <div class="grid">
            <div class="col-12">
              <div class="section">
                <h2 class="section-hero">Er du helsepersonell?</h2>
                <p class="confirmations">
                  <a href="javascript: void(0)" @click="acceptDisclaimer()"
                    >Ja</a
                  >
                  <router-link to="/">Nei</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <FeaturedImage
        v-if="post && post.mainImage"
        :title="post.title"
        :description="post.intro"
        :image="post.mainImage"
        :categories="post.categories"
        :sponsor="post.sponsor"
      />
      <div class="container post" v-if="post">
        <div class="wrapper">
          <div class="grid">
            <div class="col-12">
              <div v-if="post.sponsor" class="section full sponsor">
                <div
                  class="image"
                  v-if="post.sponsor.logo"
                  :style="{
                    backgroundImage: `url('${getImageUrl(
                      post.sponsor,
                      'logo'
                    )}')`,
                  }"
                ></div>
                <div class="details" :class="{ full: !post.sponsor.logo }">
                  <p>{{ post.sponsor.text }}</p>
                </div>
              </div>

              <Blocks
                v-if="post.body"
                :blocks="post.body"
                type="post"
                element="body"
              />

              <Sources
                v-if="post.sources && post.sources.length > 0"
                :sources="post.sources"
              />
            </div>
          </div>

          <div v-if="post.author && post.author.length > 0" class="grid author">
            <div class="col-12" v-if="post.author.length == 1">Forfatter</div>
            <div class="col-12" v-else-if="post.author.length > 1">
              Forfattere
            </div>
            <template v-for="author in post.author">
              <div
                class="col-2 image"
                :style="{
                  backgroundImage: `url('${getImageUrl(author, 'image')}')`,
                }"
                :key="'image-' + author._key"
              ></div>
              <div class="col-10 details" :key="'details-' + author._key">
                <div class="name">{{ author.name }}</div>
                <div
                  class="bio"
                  v-if="author.bio"
                  v-html="getText(author.bio)"
                ></div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import sanity from "../../sanity";
import imageUrlBuilder from "@sanity/image-url";
import text from "../../helpers/text";
import slug from "../../helpers/slug";
import Blocks from "../../components//Blocks";
import disclaimer from "../../helpers/disclaimer";
import date from "../../helpers/date";
import FeaturedImage from "../../components/FeaturedImage";
import Sources from "../../components/Sources";

const imageBuilder = imageUrlBuilder(sanity);

export default {
  components: {
    Blocks,
    FeaturedImage,
    Sources,
  },

  computed: {},

  data() {
    return {
      loading: false,
      loaded: false,
      professionals: false,
      post: null,
      posts: [],
      hasAcceptedDisclaimer: false,
    };
  },

  metaInfo() {
    return {
      title: this.loading ? "" : `${this.post.title} : `,
    };
  },

  created() {
    this.fetchPost(this.$route.params.slug);
    this.hasAcceptedDisclaimer = disclaimer.get();
  },

  mounted() {},

  watch: {
    "$route.params.slug": function(slug) {
      this.fetchPost(slug);
    },
  },

  methods: {
    fetchPost(slug) {
      this.loading = true;
      this.post = null;
      sanity
        .fetch(
          `
                    *[_type == "post" && !(_id in path('drafts.**')) && (slug.current == $slug || _id == $slug) && (publishedAt == null || publishedAt < $date)][0]{
                        ...,
                        body[]{
                            ...,
                            markDefs[] {
                                ...,
                                _type == "internalLink" => {
                                    "id": @.reference->_id,
                                    "type": @.reference_>_type,
                                    "slug": @.reference->slug
                                },
                            },
                            _type == "section" => {
                                "title": @->title,
                                "body": @->body
                            },
                            _type == "ads" => {
                                ...,
                                "title": @->title,
                                mainImage {
                                    ...,
                                    asset {
                                        ...,
                                        "metadata": @->metadata,
                                    },
                                },
                            },
                            _type == "alignedImage" => {
                                ...,
                                "title": @->title,
                                "visible": @->visible,
                                mainImage {
                                    ...,
                                    asset {
                                        ...,
                                        "metadata": @->metadata,
                                    },
                                },
                                "text": @->text,
                                "adjustment": @->adjustment,
                                "background": @->background,
                                "alignment": @->alignment,
                                "alignmentWrapper": @->alignmentWrapper,
                                "span": @->span,
                                "file": @->file,
                                "wrapper": @->wrapper,
                            },
                            _type == "pdf" => {
                                ...,
                                "title": @->title,
                                ...@-> {
                                    image {
                                        ...,
                                        asset {
                                            ...,
                                            "metadata": @->metadata,
                                        },
                                    },
                                },
                                "fullFile": @->fullFile,
                            },

                            _type == "image" => {
                                ...,
                                asset {
                                    ...,
                                    "metadata": @->metadata,
                                },
                            },
                        },
                        author[] {
                            ...,
                            "name": @->name,
                            "bio": @->bio,
                            "image": @->image,
                        },
                        categories[] {
                            ...,
                            "title": @->title,
                            "slug": @->slug,
                            "professionals": @->professionals
                        },
                        sponsor {
                            ...,
                            "title": @->title,
                            "text": @->text,
                            "logo": @->logo,
                            "info_content": @->info_content,
                        },
                        mainImage {
                            ...,
                            asset {
                                ...,
                                "metadata": @->metadata,
                            }
                        },
                    }
                `,
          {
            slug: slug,
            date: date.currentFullUTCDate(),
          }
        )
        .then(
          (response) => {
            this.post = response;
            console.log(this.post);
            this.professionals =
              this.post.categories &&
              this.post.categories.some((c) => c.professionals);
          },
          (error) => {
            console.warn(error);
          }
        )
        .then(() => {
          this.loading = false;
          this.loaded = true;
        });
    },

    getText(txt) {
      return text(txt, true);
    },

    getImageUrl(source, img = "mainImage") {
      if (!source[img]) {
        return "";
      }

      return imageBuilder.format("webp").image(source[img]);
    },

    getPosition(source, img = "mainImage") {
      if (!source[img]) {
        return "";
      }

      return source[img].position;
    },

    getSlug(element) {
      return slug(element);
    },

    acceptDisclaimer() {
      this.hasAcceptedDisclaimer = true;
      disclaimer.set(true);
    },
  },
};
</script>

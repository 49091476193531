var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[(_vm.professionals && !_vm.hasAcceptedDisclaimer)?[_c('div',{staticClass:"container post"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"section"},[_c('h2',{staticClass:"section-hero"},[_vm._v("Er du helsepersonell?")]),_c('p',{staticClass:"confirmations"},[_c('a',{attrs:{"href":"javascript: void(0)"},on:{"click":function($event){return _vm.acceptDisclaimer()}}},[_vm._v("Ja")]),_c('router-link',{attrs:{"to":"/"}},[_vm._v("Nei")])],1)])])])])])]:[(_vm.post && _vm.post.mainImage)?_c('FeaturedImage',{attrs:{"title":_vm.post.title,"description":_vm.post.intro,"image":_vm.post.mainImage,"categories":_vm.post.categories,"sponsor":_vm.post.sponsor}}):_vm._e(),(_vm.post)?_c('div',{staticClass:"container post"},[_c('div',{staticClass:"wrapper"},[_c('div',{staticClass:"grid"},[_c('div',{staticClass:"col-12"},[(_vm.post.sponsor)?_c('div',{staticClass:"section full sponsor"},[(_vm.post.sponsor.logo)?_c('div',{staticClass:"image",style:({
                  backgroundImage: ("url('" + (_vm.getImageUrl(
                    _vm.post.sponsor,
                    'logo'
                  )) + "')"),
                })}):_vm._e(),_c('div',{staticClass:"details",class:{ full: !_vm.post.sponsor.logo }},[_c('p',[_vm._v(_vm._s(_vm.post.sponsor.text))])])]):_vm._e(),(_vm.post.body)?_c('Blocks',{attrs:{"blocks":_vm.post.body,"type":"post","element":"body"}}):_vm._e(),(_vm.post.sources && _vm.post.sources.length > 0)?_c('Sources',{attrs:{"sources":_vm.post.sources}}):_vm._e()],1)]),(_vm.post.author && _vm.post.author.length > 0)?_c('div',{staticClass:"grid author"},[(_vm.post.author.length == 1)?_c('div',{staticClass:"col-12"},[_vm._v("Forfatter")]):(_vm.post.author.length > 1)?_c('div',{staticClass:"col-12"},[_vm._v(" Forfattere ")]):_vm._e(),_vm._l((_vm.post.author),function(author){return [_c('div',{key:'image-' + author._key,staticClass:"col-2 image",style:({
                backgroundImage: ("url('" + (_vm.getImageUrl(author, 'image')) + "')"),
              })}),_c('div',{key:'details-' + author._key,staticClass:"col-10 details"},[_c('div',{staticClass:"name"},[_vm._v(_vm._s(author.name))]),(author.bio)?_c('div',{staticClass:"bio",domProps:{"innerHTML":_vm._s(_vm.getText(author.bio))}}):_vm._e()])]})],2):_vm._e()])]):_vm._e()]],2)}
var staticRenderFns = []

export { render, staticRenderFns }